import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles/reset.css'
import { MoralisProvider } from 'react-moralis'

const prodAppId = 'nMah1x5OQGJORSZJwwSpbNXGFlRB0Aa3kpQ7mljg'
const prodServerUrl = 'https://x8aulbfhsc7w.usemoralis.com:2053/server'

const testAppId = 'Oyw2oTQRyZKHqxSy173cycJXt95iD0HSSk22FEMz'
const testServerUrl = 'https://4xltxux4ebjw.usemoralis.com:2053/server'

const APP_ID = process.env.REACT_APP_TESTING === 'true' ? testAppId : prodAppId
const SERVER_URL =
  process.env.REACT_APP_TESTING === 'true' ? testServerUrl : prodServerUrl

ReactDOM.render(
  <MoralisProvider serverUrl={SERVER_URL} appId={APP_ID}>
    <App />
  </MoralisProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
