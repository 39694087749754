export default function DecreaseIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        fill="#F1F4EF"
        fillOpacity="0.1"
        stroke="#F1F4EF"
        strokeMiterlimit="10"
      />
      <circle cx="11" cy="11" r="7" fill="#F1F4EF" fillOpacity="0.1" />
      <path d="M13.9431 9.936V11.64H8.66306V9.936H13.9431Z" fill="#F1F4EF" />
    </svg>
  )
}
