import Button from './Button'
import IncreaseIcon from './svgs/IncreaseIcon'
import DecreaseIcon from './svgs/DecreaseIcon'
import { useEffect, useRef, useState } from 'react'
import Web3 from 'web3'
import { Contract } from '../contract/contract'

export default function Card({ data, onBuyNft, loading }) {
  const [quantity, setQuantity] = useState(1)
  const [price, setPrice] = useState(1)
  const [currentAmount, setCurrentAmount] = useState(0)
  const renderCount = useRef(0)

  async function getCurrentAmount() {
    return await Contract.methods.typeToCount(data.nftType).call()
  }

  async function getPrice() {
    return await Contract.methods.getCost(data.nftType, quantity).call()
  }

  useEffect(() => {
    getPrice()
      .then(res => setPrice(Web3.utils.fromWei(res, 'ether')))
      .catch(() => {})

    if (renderCount.current === 0) {
      getCurrentAmount()
        .then(res => setCurrentAmount(res))
        .catch(() => {})
      renderCount.current += 1
    }
  }, [quantity])

  return (
    <div className="card">
      <img src={`/img/${data.name}.svg`} alt={data.name} />

      <div className="card-details">
        <div className="flex flex-row justify-between items-center">
          <div>Minted:</div>

          <div className="font-card-bold">
            {currentAmount}/{data.max}
          </div>
        </div>

        <div className="flex flex-row justify-between items-center">
          <div>Price:</div>

          <div>
            <span className="font-price">{price}</span>{' '}
            <span className="font-card-bold">ETH</span>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center">
          <div>Qty:</div>

          <div className="card-quantity">
            <button
              className="quantity-icon"
              onClick={() => {
                if (quantity === 1) return
                setQuantity(prev => prev - 1)
              }}
            >
              <DecreaseIcon />
            </button>
            <div className="font-card-bold quantity">{quantity}</div>
            <button
              className="quantity-icon"
              onClick={() => {
                setQuantity(prev => prev + 1)
              }}
            >
              <IncreaseIcon />
            </button>
          </div>
        </div>
      </div>

      <div className="align-self-center">
        <Button
          label={'Buy'}
          size="small"
          onClick={() => {
            getPrice()
              .then(price => onBuyNft(quantity, data.nftType, price))
              .then(() => getCurrentAmount().then(res => setCurrentAmount(res)))
              .then(() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              })
              .catch(() => {})
          }}
          disabled={loading ? true : false}
        />
      </div>
    </div>
  )
}
