export default function Button({ label, size = 'large', ...props }) {
  return (
    <button
      className={`btn btn-${size} ${props.disabled ? 'disabled' : ''}`}
      {...props}
    >
      {props.disabled ? 'Loading...' : label}
    </button>
  )
}
