const { MerkleTree } = require('merkletreejs')
const keccak256 = require('keccak256')

const prodAdd = [
  '0xAbb79d95E171234177c0e53c02Dd7f3c477d644e',
  '0x2E33ea85E088f11B592aDb6F248B87285060Ca66',
  '0xE37454F4a0BeBE847A871A3e84013805a7f53106',
  '0xCe738030Ec130Ef485B0c8C44610C672624c9Afe',
  '0x60C47a9E8D88407F511A0ea151C8BB2BbC8fA8bB',
  '0x6fd1f76B24E6CBF5808Be35781502F4Dd79662',
  '0x2D34C91357fF4c79A2d22EeA0Cf24159c81eebf7',
  '0x1AE5cF88a0Ab100ba4d6AA64f97D1bF992a467c4',
]
const testAdd = [
  '0x8563E8006F3731e96B57683C680fCA427c7d1013', // veni
  '0xd95d81211e93041435b08955b5894a301149775A', // veni
  '0x9b686E99B566907e470eB8672070fd385FB925ab', // veni
  '0xE0431Bad1E697D212246f24b55bc52A27b330b56', // yossif
  '0xF9223f7d3aE3DFaed026c2047a1E08e03f126250', // wasko2
]

let whitelistAddresses =
  process.env.REACT_APP_TESTING === 'true' ? testAdd : prodAdd

const leafNodes = whitelistAddresses.map(addr => keccak256(addr))
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true })

const rootHash = '0x' + merkleTree.getRoot().toString('hex')

function getHexProof(claimingAddress) {
  const keccak256Address = keccak256(claimingAddress)
  return merkleTree.getHexProof(keccak256Address)
}

export { rootHash, getHexProof }
