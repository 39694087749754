import React, { useEffect, useState } from 'react'
import { useChain, useMoralis } from 'react-moralis'
import './styles/app.css'
import Button from './components/Button'
import Card from './components/Card'
import { getHexProof } from './merkle'
import { Contract, contractAddress } from './contract/contract'
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button'

const cardData = [
  {
    id: 1,
    name: 'PearlMaster',
    max: 2000,
    total: 120,
    nftType: 2,
  },

  {
    id: 2,
    name: 'GoldenPearl',
    max: 3000,
    total: 479,
    nftType: 1,
  },

  {
    id: 3,
    name: 'SugarPearl',
    max: 5000,
    total: 1396,
    nftType: 0,
  },
]

function App() {
  const {
    isAuthenticated,
    authenticate,
    logout,
    isAuthenticating,
    user,
    enableWeb3,
    isWeb3Enabled,
  } = useMoralis()
  const { chainId, switchNetwork } = useChain()

  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState({
    text: 'Select your NFT category and click buy to mint your NFT.',
    color: 'white',
  })
  const [networkSwitching, setNetworkSwitching] = useState(false)

  // enable web3
  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled) {
      enableWeb3().then(() => {})
    }
  }, [isAuthenticated])

  // user address
  const userAddress = user?.attributes?.accounts
    ? user?.attributes?.accounts[0]
    : null

  // // if user changes to another account in metamask, then logout
  if (window.ethereum) {
    window.ethereum.on('accountsChanged', async function () {
      if (isAuthenticated) {
        await logout()
        setFeedback({
          text: 'Select your NFT category and click buy to mint your NFT',
          color: 'white',
        })
      }
    })
  }

  function openMetaMaskUrl(url) {
    const a = document.createElement('a')
    a.href = url
    a.target = '_self'
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  // this function runs when buy now button is clicked
  const claimNFTs = async (mintAmount, nftType, price) => {
    setFeedback({
      text: 'Select your NFT category and click buy to mint your NFT.',
      color: 'white',
    })
    setClaimingNft(true)

    let isWhitelistingEnabled = await Contract.methods
      .filterWhitelisted()
      .call({
        from: userAddress,
      })

    const hexProof = getHexProof(userAddress)

    if (isWhitelistingEnabled) {
      if (!hexProof.length) {
        setFeedback({ text: 'Current user is not whitelisted!', color: 'red' })
        return
      }
    }

    try {
      await Contract.methods.mintNft(nftType, mintAmount, hexProof).call({
        to: contractAddress,
        from: userAddress,
        value: price,
      })
    } catch (err) {
      const endIndex = err.message.search('{')

      if (endIndex >= 0) {
        setFeedback({
          text: err.message.substring(20, endIndex),
          color: 'red',
        })
        window.scroll({
          top: 0,
          behavior: 'smooth',
        })
      }

      setClaimingNft(false)

      return
    }

    try {
      await Contract.methods
        .mintNft(nftType, mintAmount, hexProof)
        .send({
          to: contractAddress,
          from: userAddress,
          value: price,
        })
        .once('error', err => {
          setFeedback({
            text: err.status ? err.message : 'User denied the transaction!',
            color: 'red',
          })
          setClaimingNft(false)
        })
        .then(receipt => {
          setFeedback({
            text: `Nft minted successfully! Visit <a target="_blank" class="link" href="${
              'https://etherscan.io/tx/' + receipt.transactionHash
            }">etherscan</a> to view it.`,
            color: 'white',
          })
          setClaimingNft(false)
        })
    } catch (e) {
      setClaimingNft(false)
    }
  }

  const network = process.env.REACT_APP_TESTING === 'true' ? '0x4' : '0x1'
  const networkName =
    process.env.REACT_APP_TESTING === 'true'
      ? 'Rinkeby Testnet'
      : 'Ethereum Mainnet'

  // when the user is not authenticated
  const authView = !isAuthenticated ? (
    <Button
      label={`Connect with Metamask`}
      size="large"
      onClick={async () => {
        if (typeof window.ethereum !== 'undefined') {
          try {
            await authenticate({
              provider: 'metamask',
              chainId: 4,
              mobileLinks: ['metamask'],
            })
          } catch (e) {}
        } else {
          openMetaMaskUrl(
            'https://metamask.app.link/dapp/ybp-minting.melioraweb.eu/'
          )
          // window.open(
          //   'https://metamask.app.link/dapp/ybp-minting.melioraweb.eu/',
          //   '_blank'
          // )
        }
      }}
      disabled={isAuthenticating}
    />
  ) : chainId !== network ? (
    <Button
      label={`Switch to ${networkName}`}
      size="large"
      onClick={async () => {
        setNetworkSwitching(true)
        try {
          await switchNetwork(network)
          setNetworkSwitching(false)
        } catch (e) {
          setNetworkSwitching(false)
        }
      }}
      disabled={networkSwitching}
    />
  ) : null

  // when the user is authenticated
  const mintView = (
    <div className="mint-view">
      <div className="topbar-wrapper">
        <div className="topbar-content">
          <img
            src="/img/YourBabyPearl.svg"
            alt="Your Baby Pearl"
            width={150}
            className="logo"
          />

          <Menu>
            <MenuButton className="menu-button" title={userAddress}>
              <div className="address-mobile">{`${userAddress?.slice(
                0,
                4
              )}...${userAddress?.slice(38)}`}</div>
              <div className="address-desktop">{`${userAddress?.slice(
                0,
                4
              )}...${userAddress?.slice(38)}`}</div>
              <div className="avatar"></div>
            </MenuButton>
            <MenuList className="menu-list">
              <MenuItem className="menu-item" onSelect={logout}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </div>

      <div
        className="feedback"
        style={{
          color: feedback.color,
        }}
        dangerouslySetInnerHTML={{ __html: feedback.text }}
      ></div>

      <div className="cards-wrapper">
        {cardData.map(item => (
          <Card
            key={item.id}
            data={item}
            onBuyNft={claimNFTs}
            loading={claimingNft}
          />
        ))}
      </div>
    </div>
  )

  return (
    <main
      className="main"
      style={{
        backgroundImage: "url('/img/Background.png')",
      }}
    >
      {!isAuthenticated || chainId !== network ? (
        <img src="/img/YourBabyPearl.svg" alt="Your Baby Pearl" />
      ) : null}

      {!authView ? mintView : authView}

      <div
        style={{
          textAlign: 'center',
        }}
      >
        <p>
          Please make sure you are connected to the right network ({networkName}
          ) and the correct address.{' '}
        </p>
        <p>
          Please note: Once you make the purchase, you cannot undo this action.
        </p>
      </div>
    </main>
  )
}

export default App
