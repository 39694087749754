import Web3 from 'web3'
import { prodABI, testABI } from './abi'

const web3 = new Web3(window.ethereum)

const prodCA = '0x04f469a2b27efecd04c451ca9dc1569b4732e400'
const testCA = '0x0268D47Bc7bd14a24EDdCf3F77461393F1aA6cbe'

const contractAddress =
  process.env.REACT_APP_TESTING === 'true' ? testCA : prodCA

const contractABI = process.env.REACT_APP_TESTING === 'true' ? testABI : prodABI

const Contract = new web3.eth.Contract(contractABI, contractAddress)

export { Contract, contractAddress }
